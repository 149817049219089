var $class="se2--promo-two-column-left-image",$name="PromoTwoColumnLeftImage",$path="app/components/PromoTwoColumnLeftImage/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';

import 'app/partials/image';

import isUsingKeyboard from 'app/utilities/isUsingKeyboard';
import parseDecimal from 'app/utilities/parseDecimal';
import setTabIndex from 'app/utilities/setTabIndex';

export const promoTwoColumnLeftImageSelector = $class;

const setContentInsetBlockStart = (content) => {
  const cardText = content.querySelector('.card-text');
  const height = cardText ? getComputedStyle(cardText).height : 0;
  const contentLinks = content.querySelectorAll('li > a');
  content.style.insetBlockStart = contentLinks.length > 1 && isUsingKeyboard()
    ? 0
    : `${parseDecimal(height)}px`;
};

const onContentResize = (entries) => entries.forEach(
  ({ target }) => {
    target.classList.add('disable-animation');
    setContentInsetBlockStart(target);
    setTimeout(
      () => target.classList.remove('disable-animation'),
      0,
    );
  },
);

const handleLinkClick = (event) => {
  const { code, target } = event;

  if (isUsingKeyboard()) {
    if (code === 'Enter') {
      if (target.classList.contains('card')) {
        const link = target.querySelector('a');
        link.click();
      }
    }
  }
};

export default shell.registerComponent($this, ({
  addEventListener, elements, mount, unmount,
}) => {
  const observers = new Map();

  mount((element) => {
    const observer = new ResizeObserver(onContentResize);
    element.querySelectorAll('.animated-content-body').forEach(
      (content) => {
        observer.observe(content);
      },
    );
    observers.set(element, observer);

    const cardLinks = element.querySelectorAll('a');
    if (cardLinks.length === 1) setTabIndex(cardLinks[0], false);

    addEventListener(element, 'keydown', (event) => {
      handleLinkClick(event);
    });
  });

  const onWindowKeydownAndMouseClick = () => {
    elements.forEach((element) => {
      element.querySelectorAll('.animated-content-body').forEach(setContentInsetBlockStart);
    });
  };

  addEventListener(window, 'keydown', onWindowKeydownAndMouseClick);
  addEventListener(window, 'click', onWindowKeydownAndMouseClick);

  unmount((element) => {
    observers.get(element)?.disconnect();
    observers.delete(element);
  });
});
